.container {
    background-color: #212741;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .logo {
    width: 100%;
    max-width: 600px;
    height: auto;
    animation: fadeIn 4s ease-out;
  }

  .title {
    text-transform: uppercase;
    color: #947b53;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 5px;
    overflow: hidden;
    white-space: nowrap;
    width: 0;
    animation: revealText 5s forwards;
  }
  
  @keyframes revealText {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
